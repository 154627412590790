// Default wrapping element for block components
import BlockContainer from './BlockContainer';

// Block components
import BlockDefault from './BlockDefault';

import BlockGridCard from './BlockGridCard';
import BlockDiscoverFavouriteBrands from './BlockDiscoverFavouriteBrands';
import BlockCategories from './BlockCategories';

// Main component: BlockBuilder
import BlockBuilder from './BlockBuilder';

export {
  BlockContainer,
  BlockDefault,
  BlockGridCard,
  BlockDiscoverFavouriteBrands,
  BlockCategories,
};

export default BlockBuilder;
